import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import {
  AccessTime,
  Security,
  CheckCircle,
  Speed,
  CloudUpload,
  Visibility,
  ExpandMore,
} from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2563EB', // Darker blue for primary color
    },
    secondary: {
      main: '#27AE60', // Light green for accent color
    },
    background: {
      default: '#F5F5F5', // Light gray for main background
      paper: '#FFFFFF', // White for card backgrounds
    },
    text: {
      primary: '#2B2D42', // Dark gray for important text
      secondary: '#333333', // Soft black for secondary text
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      color: '#2B2D42',
    },
    h2: {
      color: '#2B2D42',
    },
    h3: {
      color: '#2B2D42',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default function LandingPage() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Formulario enviado con el correo:', email);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
          <TaskAltIcon/>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            rento.la
          </Typography>
          <Button color="inherit" href="#como-funciona">Cómo Funciona</Button>
          <Button color="inherit" href="#beneficios">Beneficios</Button>
          <Button color="inherit" href="#testimonios">Testimonios</Button>
        </Toolbar>
      </AppBar>

      <main>
        <Container maxWidth="lg" sx={{ mt: 8, mb: 8, textAlign: 'center' }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Validación de Inquilinos en Minutos, No en Días
          </Typography>
          <AccessTime sx={{ fontSize: 64, color: 'secondary.main', my: 2 }} />
          <Typography variant="h5" paragraph>
            Transforma tu proceso de alquiler con una validación de inquilinos rápida, segura y confiable.
          </Typography>
          <Button variant="contained" color="secondary" size="large">
            Empezar Ahora
          </Button>
        </Container>

        <Container maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            El Problema Actual
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <AccessTime sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Proceso Lento
                  </Typography>
                  <Typography>
                    La validación tradicional puede tardar días, retrasando todo el proceso de alquiler.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Security sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Falta de Seguridad
                  </Typography>
                  <Typography>
                    Los métodos tradicionales a menudo carecen de medidas de seguridad adecuadas para la información sensible.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            Nuestra Solución
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Security sx={{ fontSize: 64, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Proceso Seguro
                  </Typography>
                  <Typography>
                    Utilizamos encriptación de última generación para proteger toda la información del inquilino.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Speed sx={{ fontSize: 64, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Validación Rápida
                  </Typography>
                  <Typography>
                    Nuestro sistema automatizado reduce el tiempo de validación de días a minutos.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container id="como-funciona" maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            Cómo Funciona
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <CloudUpload sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    1. Subir Documentos
                  </Typography>
                  <Typography>
                    Sube de forma segura los documentos necesarios a través de nuestra plataforma.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Visibility sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    2. Revisión Rápida
                  </Typography>
                  <Typography>
                    Nuestro sistema revisa y valida rápidamente la información.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <CheckCircle sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    3. Confirmación de Validación
                  </Typography>
                  <Typography>
                    Recibe confirmación instantánea de los resultados de la validación.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container id="beneficios" maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            Beneficios Clave
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Security sx={{ fontSize: 64, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Seguridad
                  </Typography>
                  <Typography>
                    Protección de datos de nivel bancario para toda la información del inquilino.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Speed sx={{ fontSize: 64, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Velocidad
                  </Typography>
                  <Typography>
                    Reduce el tiempo de validación de días a minutos, acelerando todo el proceso de alquiler.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent sx={{ textAlign: 'center' }}>
                  <CheckCircle sx={{ fontSize: 64, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Confianza
                  </Typography>
                  <Typography>
                    Genera confianza entre propietarios e inquilinos con un proceso transparente y eficiente.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container id="testimonios" maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            Lo Que Dicen Nuestros Clientes
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    María Rodríguez
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom color="text.secondary">
                    Administradora de Propiedades
                  </Typography>
                  <Typography>
                    "rento.la ha revolucionado nuestro proceso de evaluación de inquilinos. Lo que solía tomar días ahora toma minutos, y nos sentimos más seguros que nunca."
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Juan Pérez
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom color="text.secondary">
                    Inquilino
                  </Typography>
                  <Typography>
                    "Como inquilino, me encanta lo rápido y fácil que es el proceso de solicitud con rento.la. Me ha dado una ventaja en un mercado de alquiler competitivo."
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="sm" sx={{ my: 8, textAlign: 'center' }}>
          <Typography variant="h3" component="h2" gutterBottom>
            ¿Listo para Transformar tu Proceso de Alquiler?
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              label="Ingresa tu correo electrónico"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="secondary" size="large" endIcon={<CheckCircle />}>
              Empezar
            </Button>
          </form>
        </Container>

        <Container maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            Preguntas Frecuentes
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>¿Qué tan seguro es rento.la?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                rento.la utiliza medidas de encriptación y seguridad de última generación para proteger todos los datos de los usuarios. Cumplimos con todas las regulaciones relevantes de protección de datos para garantizar que su información esté segura.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>¿Cuánto tiempo tarda el proceso de validación?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Todo el proceso generalmente toma solo unos minutos. Una vez que se suben todos los documentos requeridos, nuestro sistema valida rápidamente la información y proporciona los resultados.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>¿Qué documentos se requieren para la validación?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Los documentos exactos requeridos pueden variar, pero típicamente incluyen comprobante de ingresos, identificación e historial de alquiler. La plataforma te guiará a través de los requisitos específicos para tu situación.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </main>

      <Container component="footer" maxWidth={false} sx={{ bgcolor: 'primary.main', color: 'white', py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                rento.la
              </Typography>
              <Typography>
                Transformando la validación de inquilinos para un proceso de alquiler más rápido y seguro.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                
                Contáctanos
              </Typography>
              <Typography>Correo: info@rento.la</Typography>
              <Typography>Teléfono: (+52) 9983888524</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Síguenos
              </Typography>
              <Button color="inherit" href="#">Twitter</Button>
              <Button color="inherit" href="#">Facebook</Button>
              <Button color="inherit" href="#">LinkedIn</Button>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            © 2024 rento.la. Todos los derechos reservados.
          </Typography>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
